import { instance } from "../axios";

export const getHttpRequests = async ({ page, pageSize, startDate, endDate, userId } = {}) => {
  const params = new URLSearchParams({
    ...(page && { page }),
    ...(pageSize && { pageSize }),
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
    ...(userId && { userId }),
  }).toString();

  const response = await instance.get(`audit/logs?${params}`);
  return response.data;
};

export const getAuditLogs = async (tableName, { startDate, endDate, userId } = {}) => {
  const params = new URLSearchParams({
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
    ...(userId && { userId }),
  }).toString();

  const response = await instance.get(`audit/${tableName}-audit?${params}`);
  return response.data;
};