import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Button, Grid, Paper, Typography, Box, Alert } from "@mui/material";
import { store } from "../../../store";
import { changePassword } from "../../../redux/actions/user";
import { showErrorAlert, showSuccessAlert } from "../../ui/utils/AlertUtils";
import usePasswordValidation from "../../ui/utils/ValidatePassword";
import { useTranslation } from 'react-i18next';

export const ScreenProfile = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { validatePassword, passwordError } = usePasswordValidation();
  const [showAlert, setShowAlert] = useState(false);

  const handlePasswordBlur = () => {
    validatePassword(newPassword);
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
    }

    try {
      const response = await store
        .dispatch(changePassword(user.id, newPassword))
        .catch((error) => {
          console.error("Error in dispatch: ", error);
        });

      if (response === true) {
        showSuccessAlert(t("passwordChangedSuccess"));
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        showErrorAlert(t("passwordChangeFailed"));
      }
    } catch (error) {
      console.error("Error in handlePasswordChange: ", error);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
        }}
      >
        <div style={{ width: "100%" }}>
          <Box mx={4} mt={8}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={t("lastName")}
                  value={user.name || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("firstname")}
                  value={user.firstname || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("email")}
                  value={user.email || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("phone")}
                  value={user.phoneNumber || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
          <Box mx={4} mt={4} display="flex" justifyContent="center">
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                mb={1}
              >
                {t("changeYourPassword")}
              </Typography>
              <Typography variant="body2" mb={2}>
                {t("passwordRequirements")}
              </Typography>
              <form onSubmit={handlePasswordChange}>
                <Grid item mb={2}>
                  <TextField
                    label={t("newPassword")}
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onBlur={handlePasswordBlur}
                    fullWidth
                    autoComplete="new-password"
                  />
                  {passwordError && (
                    <Grid item mb={2}>
                      <Alert severity="error">{passwordError}</Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid item mb={2}>
                  <TextField
                    label={t("confirmNewPassword")}
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    fullWidth
                    autoComplete="new-password"
                  />
                </Grid>
                {showAlert && (
                  <Grid item mb={2}>
                    <Alert severity="error">{t("passwordsDoNotMatch")}</Alert>
                  </Grid>
                )}
                <Grid item mb={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: "16px 32px" }}
                    fullWidth
                    type="submit"
                  >
                    {t("changePassword")}
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Box>
        </div>
      </Paper>
    </Grid>
  );
};