import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Box } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const AttributeToPatientDialog = ({
  open,
  onClose,
  selectedStatusRow,
  patientNumber,
  setPatientNumber,
  attributionDate,
  setAttributionDate,
  showAlert,
  showDateAlert,
  handleStatusFormSubmit,
  setShowDateAlert
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      PaperProps={{
        style: { padding: "20px" },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t("attributeToPatient")}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {selectedStatusRow ? selectedStatusRow.Material.name : ""}{" "}
      </DialogContentText>
      <DialogContentText>
        {t("reference")} {selectedStatusRow ? selectedStatusRow.reference : ""}
      </DialogContentText>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
        <TextField
          label={t("patientNumber")}
          onChange={(e) => setPatientNumber(e.target.value)}
          sx={{ marginRight: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            label={t("attributionDate")}
            name="attributionDate"
            value={dayjs(attributionDate)}
            onChange={(newValue) => {
              if (dayjs(newValue).isAfter(dayjs(selectedStatusRow.expirationDate))) {
                setShowDateAlert(true);
              } else {
                setShowDateAlert(false);
                setAttributionDate(newValue.format("YYYY-MM-DD"));
              }
            }}
            format="DD/MM/YYYY"
            maxDate={dayjs()} // Disable dates after today
            sx={{ width: "50%" }}
          />
        </LocalizationProvider>
      </Box>
      {showAlert && (
        <div style={{ color: "red", marginTop: "10px" }}>
          {t("pleaseEnterPatientNumber")}
        </div>
      )}
      {showDateAlert && (
        <div style={{ color: "red", marginTop: "10px" }}>
          {t("selectedDateAfterExpiration")}
        </div>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{t("cancel")}</Button>
      <Button
        variant="contained"
        color="primary"
        style={{ padding: "16px", margin: "16px" }}
        onClick={() => handleStatusFormSubmit(patientNumber, attributionDate)}
      >
        {t("save")}
      </Button>
    </DialogActions>
    </Dialog>
  );
};

export default AttributeToPatientDialog;