import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const FirstSupplyDialog = ({
  open,
  handleClose,
  isFirstSupply,
  setIsFirstSupply,
  handleFSConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        style: { padding: "20px" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("isFirstSupplyOrder")}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={isFirstSupply}
              onChange={(event) => setIsFirstSupply(event.target.checked)}
              aria-label="FirstSupply"
            />
          }
          label={t("firstSupply")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("close")}</Button>
        <Button onClick={handleFSConfirm} variant="contained">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirstSupplyDialog;
