import React from 'react';
import { Box, Typography } from '@mui/material';

const ContentCellRenderer = ({ value }) => {
  if (!value) return null;

  const content = JSON.parse(value);

  return (
    <Box display="flex" flexWrap="wrap">
      {Object.entries(content).map(([key, val]) => (
        <Typography key={key} variant="body2" style={{ marginRight: '8px' }}>
          <strong>{key}:</strong> {val !== null && val !== undefined ? val.toString() : ''}
        </Typography>
      ))}
    </Box>
  );
};

export default ContentCellRenderer;