import React from 'react';
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Grid,
  Box,
  IconButton,
  Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import countries from 'i18n-iso-countries';
import { store } from '../../store';
import { createSponsor } from '../../redux/actions/sponsor';
import { showSuccessAlert } from '../ui/utils/AlertUtils';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
const countryList = countries.getNames('en', { select: 'official' });

export const ModalSponsor = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const isLoading = useSelector((state) => state.sponsor.isLoadingSponsor);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!name.trim()) newErrors.name = t('nameRequired');
    if (!address.trim()) newErrors.address = t('addressRequired');
    if (!city.trim()) newErrors.city = t('cityRequired');
    if (!country.trim()) newErrors.country = t('countryRequired');

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const created = await store.dispatch(createSponsor({ name, address, city, country }));
      if (created) {
        showSuccessAlert(t('sponsorCreatedSuccess'));
        // Reset form fields
        setName('');
        setAddress('');
        setCity('');
        setCountry('');
      }
      onClose(true);
    }
  };

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              {t('createSponsor')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              {t('pleaseIndicateFields')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                id="input-name"
                label={t('name')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                id="input-address"
                label={t('address')}
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                error={!!errors.address}
                helperText={errors.address}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                id="input-city"
                label={t('city')}
                value={city}
                onChange={(event) => setCity(event.target.value)}
                error={!!errors.city}
                helperText={errors.city}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.country}>
              <InputLabel id="select-country">{t('country')}</InputLabel>
              <Select
                labelId="select-country"
                id="select-country"
                value={country}
                onChange={(event) => setCountry(event.target.value)}
                name="select-country"
                label={t('country')}
              >
                {Object.entries(countryList).map(([code, name]) => (
                  <MenuItem key={code} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.country}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, padding: 2 }}
            >
              {t('create')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};