import * as React from "react";
import { useNavigate } from "react-router-dom";
import Login from "./components/screens/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Protected } from "./components/screens/Protected";
import { ScreenUsers } from "./components/screens/protected/Users";
import { ScreenProductions } from "./components/screens/protected/Productions";
import { ScreenStudies } from "./components/screens/protected/Studies";
import { ScreenMaterials } from "./components/screens/protected/Materials";
import { ScreenOrders } from "./components/screens/protected/Orders";
import { ScreenCenters } from "./components/screens/protected/Centers";
import { ScreenSponsors } from "./components/screens/protected/Sponsors";
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { ScreenCreateOrderForm } from "./components/screens/protected/CreateOrderForm";
import { ScreenConfirmOrderForm } from "./components/screens/protected/ConfirmOrderForm";
import { ScreenCenterInfo } from "./components/screens/protected/CenterInfo";
import { ScreenOrderInfo } from "./components/screens/protected/OrderInfo";
import { ScreenCreateShippingForm } from "./components/screens/protected/CreateShippingForm";
import { ScreenConfirmShippingForm } from "./components/screens/protected/ConfirmShippingForm";
import { ScreenShippingInfo } from "./components/screens/protected/ShipmentInfo";
import { ScreenAorForm } from "./components/screens/protected/AorForm";
import { ScreenAor } from "./components/screens/protected/Aor";
import { ScreenStudyInfo } from "./components/screens/protected/StudyInfo";
import { ScreenProfile } from "./components/screens/protected/Profile";
import LegalNotice from "./components/screens/LegalNotice";
import { ScreenAudit } from "./components/screens/protected/Audit";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#1e3179",
    },
    secondary: {
      main: "#a64d79",
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: grey[300],
          },
        },
      },
    },
  },
});

export default function App() {
  const navigate = useNavigate();
  const tokenAccess = useSelector((state) => state.root.tokenAccess);
  const user = useSelector((state) => state.user.user);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [centerUser, setCenterUser] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (user && tokenAccess) {
      setCenterUser(user.organization === "Center");
      setIsAdmin(user.role === "admin");
      setLoading(false);
    } else {
      navigate("/");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, tokenAccess]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div data-testid="app">
      <ThemeProvider theme={defaultTheme}>
        <Routes>
          {tokenAccess ? (
            <Route path="/" element={<Protected />}>
              <Route
                path="/"
                element={
                  centerUser ? (
                    <Navigate to="/productions" />
                  ) : (
                    <Navigate to="/studies" />
                  )
                }
              />
              <Route
                path="users"
                element={
                  isAdmin ? (
                    <ScreenUsers />
                  ) : (
                    <Navigate to={centerUser ? "/productions" : "/studies"} />
                  )
                }
              />
              <Route
                path="studies"
                element={
                  !centerUser ? (
                    <ScreenStudies />
                  ) : (
                    <Navigate to="/productions" />
                  )
                }
              />
              <Route
                path="study-info"
                element={
                  isAdmin ? (
                    <ScreenStudyInfo />
                  ) : (
                    <Navigate to={centerUser ? "/productions" : "/studies"} />
                  )
                }
              />
              <Route path="orders" element={<ScreenOrders />} />
              <Route path="order" element={<ScreenOrderInfo />} />
              <Route path="shipping" element={<ScreenShippingInfo />} />
              <Route
                path="shipping/new"
                element={<ScreenCreateShippingForm />}
              />
              <Route
                path="shipping/confirm"
                element={<ScreenConfirmShippingForm />}
              />
              <Route path="aor" element={<ScreenAor />} />
              <Route path="aor-form" element={<ScreenAorForm />} />
              <Route path="productions" element={<ScreenProductions />} />
              <Route path="materials" element={<ScreenMaterials />} />
              <Route
                path="centers"
                element={
                  !centerUser ? (
                    <ScreenCenters />
                  ) : (
                    <Navigate to="/productions" />
                  )
                }
              />
              <Route
                path="center-info"
                element={
                  isAdmin ? (
                    <ScreenCenterInfo />
                  ) : (
                    <Navigate to={centerUser ? "/productions" : "/studies"} />
                  )
                }
              />
              <Route
                path="sponsors"
                element={
                  isAdmin ? (
                    <ScreenSponsors />
                  ) : (
                    <Navigate to={centerUser ? "/productions" : "/studies"} />
                  )
                }
              />
              <Route path="orders/new" element={<ScreenCreateOrderForm />} />
              <Route
                path="orders/confirm"
                element={<ScreenConfirmOrderForm />}
              />
              <Route path="profile" element={<ScreenProfile />} />
              <Route path="/*" element={<Navigate to="/" />} />
              <Route path="/legal" element={<LegalNotice />} />
              <Route
                path="/audit"
                element={isAdmin ? <ScreenAudit /> : <Navigate to="/" />}
              />
            </Route>
          ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/legal" element={<LegalNotice />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </ThemeProvider>
    </div>
  );
}
