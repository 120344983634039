import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createProtocol } from '../../../services/protocolService';
import { showErrorAlert, showSuccessAlert } from '../../ui/utils/AlertUtils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const NewProtocolDialog = ({ open, handleClose, setOpen, setProtocolChanged, studyId }) => {
  const { t } = useTranslation();
  const [reference, setReference] = useState('');
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));

  const handleCreateProtocol = async (event) => {
    event.preventDefault();
    setOpen(false);
    const body = { studyId, startDate, reference };
    try {
      const response = await createProtocol(body);
      if (response) {
        setProtocolChanged(true);
        showSuccessAlert(t("protocolCreatedSuccess"));
      }
    } catch (error) {
      showErrorAlert(t("protocolCreateFailed"));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { padding: "20px" },
      }}
    >
      <DialogTitle id="form-dialog-title">{t("newProtocol")}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            autoFocus
            margin="dense"
            id="reference"
            label={t("reference")}
            type="text"
            fullWidth
            value={reference}
            onChange={(event) => setReference(event.target.value)}
          />
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            label={t("startDate")}
            name="start_date"
            value={dayjs(startDate)}
            onChange={(newValue) => {
              setStartDate(newValue.format("YYYY-MM-DD"));
            }}
            sx={{ width: "100%" }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          {t("cancel")}
        </Button>
        <Button onClick={handleCreateProtocol} color="primary" variant="contained">
          {t("create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewProtocolDialog;