import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import * as React from "react";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";
import Fab from "@mui/material/Fab";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import { ModalCenter } from "../../modals/Center";
import { getCenters, getMonitorCenters } from "../../../redux/actions/center";
import { store } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CSVDownloader from "../../ui/CSVDownloader";
import { getToday } from "../../ui/utils/DateUtils";
import CustomFilter from "../../table/CustomFilter";
import getUniqueOptions from "../../table/getUniqueOptions";
import { useTranslation } from 'react-i18next';

const SPACING = 50;

export const ScreenCenters = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const isMonitor = user.role === "monitor";
  const adminUser = user.role === "admin";
  const studyCode = location.state?.studyCode;
  const studyId = location.state?.studyId;
  const centers = useSelector((state) => state.center.centers);
  const isLoading = useSelector((state) => state.center.isLoadingCenters);
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [selectedCentersForDownload, setSelectedCentersForDownload] =
    React.useState([]);
  const [countryOptions, setCountryOptions] = React.useState([]);

  React.useEffect(() => {
    if (centers.length > 0) {
      setCountryOptions(getUniqueOptions(centers, 'country'));
    }
  }, [centers]);

  const columns = [
    {
      field: "centerNumber",
      headerName: t("centerNumber"),
      flex: 0.2,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/productions`, {
              state: {
                centerId: params.row.id,
                centerNumber: params.row.centerNumber,
                studyId: studyId,
                studyCode: studyCode,
              },
            })
          }
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "country",
      headerName: t("country"),
      flex: 0.2,
      renderCell: (params) => params.value.toUpperCase(),
      filterable: true,
      filterOperators: [
        {
          label: t('country'),
          value: 'country',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter
              {...props}
              options={countryOptions}
            />
          ),
        },
      ],
    },
    {
      field: "city",
      headerName: t("city"),
      flex: 0.2,
      renderCell: (params) =>
        params.value
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" "),
    },
    { field: "status", headerName: t("status"), flex: 0.1 },
    {
      field: "contactName",
      headerName: t("contact"),
      flex: 0.3,
      renderCell: (params) => {
        if (!params.value || !params.value.includes(",")) {
          return params.value;
        }
        const parts = params.value.split(",");
        const lastName = parts[0].trim();
        const firstName = parts.length > 1 ? parts[1].trim() : "";
        const formattedFirstName = firstName
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");
        return `${lastName}, ${formattedFirstName}`;
      },
    },
    {
      field: "Protocols",
      headerName: t("protocol"),
      flex: 0.2,
      renderCell: (params) => {
        const protocols = params.row.Protocols;
        const references = protocols?.map((protocol) => protocol.reference);
        const referencesString = references?.join(", ");
        return referencesString;
      },
    },
  ];

  React.useEffect(() => {
    if (isMonitor) {
      store.dispatch(getMonitorCenters(user.id));
    } else if (studyId) {
      store.dispatch(getCenters(studyId));
    } else {
      navigate("/studies");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "85vh" }}>
          <LocalizedDataGrid
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={centers}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50]}
            checkboxSelection
            rowHeight={40}
            onRowSelectionModelChange={(newSelection) => {
              const selectedCenters = centers
                ?.filter((center) => newSelection.includes(center.id))
                .map((center) => {
                  const filteredCenter = {};
                  columns.forEach((column) => {
                    if (column.field === "Protocols") {
                      const protocols = center[column.field];
                      const references = protocols?.map(
                        (protocol) => protocol.reference
                      );
                      const referencesString = references?.join(", ");
                      filteredCenter[column.headerName] = referencesString;
                    } else {
                      filteredCenter[column.headerName] = center[column.field];
                    }
                  });
                  return filteredCenter;
                });
              setSelectedCentersForDownload(selectedCenters);
            }}
          />
        </div>
        {selectedCentersForDownload.length > 0 && (
          <CSVDownloader
            data={selectedCentersForDownload}
            filename={`${studyCode}_centers_${getToday()}`}
            name={t("selectedCenters")}
          />
        )}
      </Paper>
      {adminUser && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setVisibleCreateEdit(true);
            }}
            style={{ position: "absolute", right: SPACING, bottom: SPACING }}
          >
            <AddIcon />
          </Fab>
          <ModalCenter
            visible={visibleCreateEdit}
            studyId={studyId}
            studyCode={studyCode}
            onClose={(refresh) => {
              setVisibleCreateEdit(false);
              if (refresh) {
                store.dispatch(getCenters(studyId));
              }
            }}
          ></ModalCenter>
        </>
      )}
    </Grid>
  );
};