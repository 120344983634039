import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EditMaterialDialog = ({
  open,
  handleClose,
  handleEdit,
  handleDeleteMaterial,
  name,
  setName,
  type,
  setType,
  status,
  setStatus,
  count
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        style: { padding: "20px" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("editMaterialType")}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleEdit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                label={t("name")}
                style={{ marginTop: "20px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type-label">{t("type")}</InputLabel>
                <Select
                  label="type"
                  labelId="type-label"
                  id="type"
                  name="type"
                  value={type}
                  onChange={(event) => setType(event.target.value)}
                  required
                >
                  <MenuItem value={"material"}>{t("material")}</MenuItem>
                  <MenuItem value={"supply"}>{t("supply")}</MenuItem>
                  <MenuItem value={"reagent"}>{t("reagent")}</MenuItem>
                  <MenuItem value={"document"}>{t("document")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="status-label">{t("status")}</InputLabel>
                <Select
                  labelId="status-label"
                  label="status"
                  id="status"
                  name="status"
                  value={status}
                  onChange={(event) => setStatus(event.target.value)}
                  required
                >
                  <MenuItem value={"active"}>{t("active")}</MenuItem>
                  <MenuItem value={"finished"}>{t("finished")}</MenuItem>
                  <MenuItem value={"planned"}>{t("planned")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {count === 0 ? (
            <Button
              onClick={handleDeleteMaterial}
              color="secondary"
              variant="contained"
              style={{ marginTop: "20px" }}
            >
              {t("deleteMaterial")}
            </Button>
          ) : null}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          {t("cancel")}
        </Button>
        <Button onClick={handleEdit} color="primary" variant="contained">
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMaterialDialog;