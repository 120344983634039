import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { ModalMaterial } from "../../modals/Material";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { deleteMaterial, editMaterial } from "../../../redux/actions/material";
import {
  getMaterials,
  getMaterialsByCenter,
} from "../../../redux/actions/material";
import { store } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import CSVDownloader from "../../ui/CSVDownloader";
import { getToday } from "../../ui/utils/DateUtils";
import { Button } from "@mui/material";
import {
  addMaterialToProtocol,
  getCenterProtocols,
  getProtocols,
} from "../../../services/protocolService";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../ui/utils/AlertUtils";
import { useTranslation } from "react-i18next";
import EditMaterialDialog from "../../ui/dialogs/EditMaterialDialog";
import ProtocolSelectorDialog from "../../ui/dialogs/ProtocolSelectorDialog";

const SPACING = 50;

export const ScreenMaterials = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const studyCode = location.state?.studyCode;
  const centerNumber = location.state?.centerNumber;
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const centerUser = user.organization === "Center";
  const isLoading = useSelector((state) => state.material.isLoadingMaterials);
  const studyId = location.state?.studyId;
  const centerId = location.state?.centerId;
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedCell, setSelectedCell] = React.useState(null);
  const [name, setName] = React.useState("");
  const [type, setType] = React.useState("");
  const [count, setCount] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [showProtocolSelector, setShowProtocolSelector] = React.useState(false);
  const [protocols, setProtocols] = React.useState([]);
  const [centerProtocols, setCenterProtocols] = React.useState([]);
  const [selectedProtocol, setSelectedProtocol] = React.useState("");
  const materials = useSelector((state) => state.material.materials);
  const updatedMaterialState = useSelector(
    (state) => state.material.updatedMaterial
  );
  const deletedMaterial = useSelector(
    (state) => state.material.deletedMaterial
  );
  const [updatedProtocolMaterials, setUpdatedProtocolMaterials] =
    React.useState(false);

  const handleAddMaterialToProtocol = async (protocolId) => {
    const materialIds = selectedRows;
    materialIds.forEach((materialId) => {
      if (materialId) {
        addMaterialToProtocol(protocolId, materialId);
      }
    });
  };

  const handleProtocolSelect = (event) => {
    const protocolId = event.target.value;
    setSelectedProtocol(protocolId);
  };

  const handleConfirm = () => {
    if (selectedProtocol) {
      handleAddMaterialToProtocol(selectedProtocol);
      setShowProtocolSelector(false);
      showSuccessAlert(t("materialsAddedToProtocol"));
      setUpdatedProtocolMaterials(true);
    }
  };

  const handleCellDoubleClick = (params) => {
    if (params.field === "name") {
      setSelectedCell(params.id);
      const selectedMaterial = materials.find(
        (material) => material.id === params.id
      );
      setName(selectedMaterial.name);
      setType(selectedMaterial.type);
      setStatus(selectedMaterial.status);
      setCount(selectedMaterial.totalCount);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteMaterial = async () => {
    setOpen(false);
    try {
      const result = await showWarningAlert(t("deleteMaterialConfirmation"));
      if (result.isConfirmed) {
        await store.dispatch(deleteMaterial(selectedCell));
        showSuccessAlert(t("materialDeleted"));
      }
    } catch (error) {
      console.error(error);
      showErrorAlert(t("materialDeleteFailed"));
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const updatedMaterial = {
      name: name,
      type: type,
      status: status,
    };
    setOpen(false);
    try {
      const response = await store.dispatch(
        editMaterial(selectedCell, updatedMaterial)
      );
      if (response) {
        showSuccessAlert(t("materialUpdated"));
      } else {
        showErrorAlert(t("materialUpdateFailed"));
      }
    } catch (error) {
      console.error(error);
      showErrorAlert(t("materialUpdateFailed"));
    }
  };

  const sortedMaterials = Array.isArray(materials)
    ? [...materials]
        .filter((material) => material.type === "material")
        .sort((a, b) => {
          const idA = a.Protocols?.[0]?.id ?? 0;
          const idB = b.Protocols?.[0]?.id ?? 0;
          if (idA !== idB) {
            return idB - idA;
          }
  
          // Extract numerical part from the name
          const extractNumber = (name) => {
            const match = name.match(/(\d+)/);
            return match ? parseInt(match[0], 10) : 0;
          };
  
          const numA = extractNumber(a.name);
          const numB = extractNumber(b.name);
  
          if (numA !== numB) {
            return numA - numB;
          }
  
          return a.name.localeCompare(b.name);
        })
        .concat(materials.filter((material) => material.type !== "material"))
    : [];

  const columns = React.useMemo(() => {
    if (centerId) {
      return [
        { field: "name", headerName: t("material"), width: 500 },
        {
          field: "productionCount",
          headerName: t("stock"),
          width: 100,
        },
        {
          field: "notes",
          headerName: "",
          width: 400,
          renderCell: (params) => (
            <div
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={() =>
                navigate(`/productions`, {
                  state: {
                    centerId: centerId,
                    centerNumber: centerNumber,
                    studyId: studyId,
                    studyCode: studyCode,
                    materialId: params.row.id,
                    expiring: true,
                  },
                })
              }
            >
              {params.value}
            </div>
          ),
        },
        {
          field: "protocols",
          headerName: t("protocol"),
          width: 200,
          renderCell: (params) => {
            if (params.value) {
              return params.value
                .map((protocol) => protocol.reference)
                .join(", ");
            } else {
              return "";
            }
          },
        },
      ];
    } else {
      if (!centerUser) {
        return [
          { field: "name", headerName: t("materialType"), width: 500 },
          { field: "availableCount", headerName: t("available"), width: 100 },
          { field: "inTransitCount", headerName: t("inTransit"), width: 100 },
          {
            field: "atCenterCount",
            headerName: t("atCenter"),
            width: 100,
          },
          {
            field: "attributedCount",
            headerName: t("attributed"),
            width: 100,
            valueGetter: (params) =>
              params.row.type === "material" ? params.value : "NA",
          },
          {
            field: "destructionCount",
            headerName: t("destroyed"),
            width: 100,
          },
          { field: "totalCount", headerName: t("total"), width: 100 },
          {
            field: "Protocols",
            headerName: t("usedInProtocols"),
            width: 200,
            renderCell: (params) => {
              if (params.value) {
                return params.value
                  .map((protocol) => protocol.reference)
                  .join(", ");
              } else {
                return "Loading...";
              }
            },
          },
        ];
      } else {
        return [{ field: "name", headerName: t("material"), width: 500 }];
      }
    }
  }, [centerId, centerUser, centerNumber, studyId, studyCode, navigate, t]);

  // Extract the field values from the columns array
  const visibleColumns = columns.map((column) => column.field);
  const headerMapping = columns.reduce((acc, column) => {
    acc[column.field] = column.headerName;
    return acc;
  }, {});
  headerMapping["Protocols"] = t("usedInProtocols");

  // Filter the data based on the visible columns and replace keys with headerName
  const materialsToDownload =
    !centerUser && selectedRows.length > 0
      ? sortedMaterials.filter((material) => selectedRows.includes(material.id))
      : sortedMaterials;

  const filteredMaterials = materialsToDownload.map((row) => {
    const filteredRow = {};
    for (const key in row) {
      if (key === "protocols" || key === "Protocols") {
        // Map over the array and join the elements into a string
        filteredRow[headerMapping[key]] = row[key]
          .map((protocol) => protocol.reference)
          .join(", ");
      } else if (visibleColumns.includes(key)) {
        filteredRow[headerMapping[key]] = row[key];
      }
    }
    return filteredRow;
  });

  // Fetch materials when a material is updated
  React.useEffect(() => {
    const fetchData = async () => {
      if (centerId) {
        await store.dispatch(getMaterialsByCenter(centerId));
      } else if (studyId) {
        await store.dispatch(getMaterials(studyId));
      }
    };
    fetchData();
  }, [
    updatedMaterialState,
    updatedProtocolMaterials,
    deletedMaterial,
    centerId,
    studyId,
  ]);

  // Fetch materials
  React.useEffect(() => {
    const fetchData = async () => {
      if (centerId) {
        await store.dispatch(getMaterialsByCenter(centerId));
      } else if (studyId) {
        await store.dispatch(getMaterials(studyId));
      } else {
        navigate("/studies");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, centerId]);

  // Fetch protocols
  React.useEffect(() => {
    if (showProtocolSelector) {
      getProtocols(studyId).then((response) => {
        setProtocols(response);
      });
    }
  }, [showProtocolSelector, studyId]);

  // Fetch center protocols
  React.useEffect(() => {
    if (centerId) {
      getCenterProtocols(centerId).then((response) => {
        setCenterProtocols(response);
      });
    }
  }, [centerId]);

  // Set the selected protocol to the first protocol in the list by default
  React.useEffect(() => {
    if (protocols.length > 0) {
      setSelectedProtocol(protocols[0].id);
    } else {
      setSelectedProtocol("");
    }
  }, [protocols]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {centerId && (
          <div
            style={{
              color: "grey",
              textAlign: "right",
              padding: "10px",
            }}
          >
            {t("currentProtocolsApplicable")}{" "}
            {centerProtocols.map((protocol) => protocol.reference).join(", ")}
          </div>
        )}
        <div style={{ width: "100%", height: "80vh" }}>
          {materials ? (
            <LocalizedDataGrid
              autoWidth
              slots={{
                noRowsOverlay: NoRowOverlay,
                loadingOverlay: LinearProgress,
              }}
              rows={sortedMaterials}
              columns={columns}
              loading={isLoading}
              hideFooter
              checkboxSelection={!centerUser && !centerId}
              onRowSelectionModelChange={(selection) => {
                setSelectedRows(selection);
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[50, 100]}
              rowHeight={30}
              onCellDoubleClick={
                adminUser && !centerId ? handleCellDoubleClick : null
              }
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div>
          <EditMaterialDialog
            open={open}
            handleClose={handleClose}
            handleEdit={handleEdit}
            handleDeleteMaterial={handleDeleteMaterial}
            name={name}
            setName={setName}
            type={type}
            setType={setType}
            status={status}
            setStatus={setStatus}
            count={count}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <CSVDownloader
            data={filteredMaterials}
            filename={`${studyCode}${
              centerNumber ? `_${centerNumber}` : ""
            }_materials_${getToday()}`}
            name={t("materialList")}
          />
          {!centerId && adminUser && selectedRows.length > 0 && (
            <Button
              type="submit"
              disabled={isLoading}
              onClick={() => setShowProtocolSelector(true)}
              variant="contained"
              color="primary"
              style={{ padding: "16px 88px", margin: "16px" }}
            >
              {t("addToProtocol")}
            </Button>
          )}
          <ProtocolSelectorDialog
            open={showProtocolSelector}
            handleClose={() => setShowProtocolSelector(false)}
            protocols={protocols}
            selectedProtocol={selectedProtocol}
            handleProtocolSelect={handleProtocolSelect}
            handleConfirm={handleConfirm}
          />
        </div>
      </Paper>
      {adminUser && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setVisibleCreateEdit(true);
            }}
            style={{ position: "absolute", right: SPACING, bottom: SPACING }}
          >
            <AddIcon />
          </Fab>
          <ModalMaterial
            visible={visibleCreateEdit}
            studyId={studyId}
            studyCode={studyCode}
            onClose={(refresh) => {
              setVisibleCreateEdit(false);
              if (refresh) {
                store.dispatch(getMaterials(studyId));
              }
            }}
          ></ModalMaterial>
        </>
      )}
    </Grid>
  );
};
