import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function OrderTable({
  order,
  studyCode,
  centerNumber,
  centerId,
  cdlUser,
  studyId,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleShipmentDetails = (shipmentIndex) => {
    navigate("/shipping", {
      state: {
        order,
        orderId: order.id,
        centerNumber,
        studyCode,
        studyId,
        shipmentIndex,
        centerId,
      },
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>{t("study")}</TableCell>
            <TableCell>{t("centerNumber")}</TableCell>
            <TableCell>{t("orderNumber")}</TableCell>
            <TableCell>{t("orderDate")}</TableCell>
            {((cdlUser && order.status !== "placed") ||
              (!cdlUser && order.status === "confirmed")) && (
              <TableCell>{t("confirmationDate")}</TableCell>
            )}
            <TableCell>{t("status")}</TableCell>
            {order.shipments && order.shipments.length > 0 && (
              <>
                <TableCell>{t("sendingDate")}</TableCell>
                <TableCell>{t("deliveryDate")}</TableCell>
              </>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{studyCode}</TableCell>
            <TableCell>{centerNumber}</TableCell>
            <TableCell>{order.orderNumber}</TableCell>
            <TableCell>
              {new Date(order.orderDate).toLocaleDateString("en-GB")}
            </TableCell>
            {((cdlUser && order.status !== "placed") ||
              (!cdlUser && order.status === "confirmed")) && (
              <TableCell>
                {new Date(order.confirmationDate).toLocaleDateString("en-GB")}
              </TableCell>
            )}
            <TableCell> {t(`statusValues.${order.status}`)}</TableCell>
            {order.shipments && order.shipments.length > 0 && (
              <>
                <TableCell>
                  {format(
                    new Date(order.shipments[0]?.sendingDate),
                    "dd/MM/yyyy"
                  )}
                </TableCell>
                <TableCell>
                  {order.shipments[0].deliveryDate
                    ? format(
                        new Date(order.shipments[0]?.deliveryDate),
                        "dd/MM/yyyy"
                      )
                    : t("notDeliveredYet")}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
      {order.shipments &&
        cdlUser &&
        order.shipments.map((shipment, index) => (
          <Table sx={{ minWidth: 650 }} aria-label="simple table" key={index}>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                  {t("shipment")} {index + 1}:
                </TableCell>
                <TableCell style={{ fontWeight: "800" }}>{t("status")}</TableCell>
                <TableCell style={{ fontWeight: "800" }}>{t("sendingDate")}</TableCell>
                <TableCell style={{ fontWeight: "800" }}>{t("deliveryDate")}</TableCell>
                {cdlUser && (
                  <TableCell style={{ fontWeight: "800" }}>{t("airwaybill")}</TableCell>
                )}
                {shipment.comment && (
                  <TableCell style={{ fontWeight: "800" }}>{t("comment")}</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: "6px 16px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: "16px 24px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    onClick={() => handleShipmentDetails(index)}
                  >
                    {t("shippingDetails")}
                  </Button>
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>
                  {t(`statusValues.${shipment.status}`)}
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>
                  {format(new Date(shipment.sendingDate), "dd/MM/yyyy")}
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>
                  {shipment.deliveryDate
                    ? format(new Date(shipment.deliveryDate), "dd/MM/yyyy")
                    : t("notDeliveredYet")}
                </TableCell>
                {cdlUser && (
                  <TableCell style={{ padding: "6px 16px" }}>
                    {shipment.packingSlip}
                  </TableCell>
                )}
                {shipment.comment && (
                  <TableCell style={{ padding: "6px 16px" }}>
                    {shipment.comment}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        ))}
    </TableContainer>
  );
}