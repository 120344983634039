import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AssignDialog = ({
  open,
  handleClose,
  selectedUser,
  studies,
  centers,
  selectedStudies,
  selectedCenters,
  handleStudyChange,
  handleCenterChange,
  handleConfirm
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { padding: "20px" },
      }}
    >
      <DialogTitle>
        {selectedUser?.role === "monitor"
          ? t("selectCenters")
          : t("selectStudies")}{" "}
        {t("toAssignTo")} {selectedUser?.firstname} {selectedUser?.name}{" "}
      </DialogTitle>
      <DialogContent>
        {selectedUser?.role === "manager" ||
        selectedUser?.organization === "CDL" ? (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t("studies")}</FormLabel>
            <FormGroup>
              {studies.map((study) => (
                <FormControlLabel
                  key={study.id}
                  control={
                    <Checkbox
                      checked={selectedStudies.includes(study.id)}
                      onChange={(e) => handleStudyChange(e, study.id)}
                      name={study.cdlStudyCode}
                    />
                  }
                  label={study.cdlStudyCode}
                />
              ))}
            </FormGroup>
          </FormControl>
        ) : selectedUser?.role === "monitor" ? (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t("centers")}</FormLabel>
            <FormGroup>
              {centers.map((center) => (
                <FormControlLabel
                  key={center.id}
                  control={
                    <Checkbox
                      checked={selectedCenters.includes(center.id)}
                      onChange={(e) => handleCenterChange(e, center.id)}
                      name={center.centerNumber}
                    />
                  }
                  label={center.centerNumber}
                />
              ))}
            </FormGroup>
          </FormControl>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          {t("cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignDialog;