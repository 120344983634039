import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import * as React from "react";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";
import Fab from "@mui/material/Fab";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import { ModalSponsor } from "../../modals/Sponsor";
import { store } from "../../../store";
import { getSponsors, updateSponsor } from "../../../redux/actions/sponsor";
import { showSuccessAlert } from "../../ui/utils/AlertUtils";
import { useTranslation } from "react-i18next";
import EditSponsorDialog from "../../ui/dialogs/EditSponsorDialog";

const SPACING = 50;

export const ScreenSponsors = (props) => {
  const { t } = useTranslation();
  const sponsors = useSelector((state) => state.sponsor.sponsors);
  const isLoading = useSelector((state) => state.sponsor.isLoadingSponsors);
  const user = useSelector((state) => state.user.user);
  const admin = user.role === "admin";
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedSponsor, setSelectedSponsor] = React.useState(null);
  const updatedSponsor = useSelector((state) => state.sponsor.updatedSponsor);

  const handleCellDoubleClick = (params) => {
    if (params.field === "name" && admin) {
      setSelectedSponsor(params.row);
      setOpenDialog(true);
    }
  };

  const handleSave = async () => {
    setOpenDialog(false);
    await store.dispatch(updateSponsor(selectedSponsor));
    showSuccessAlert("Sponsor updated successfully");
  };

  React.useEffect(() => {
    store.dispatch(getSponsors());
  }, [updatedSponsor]);

  const columns = [
    { field: "name", headerName: t("name"), flex: 0.2 },
    { field: "address", headerName: t("address"), flex: 0.3 },
    { field: "city", headerName: t("city"), flex: 0.2 },
    { field: "country", headerName: t("country"), flex: 0.2 },
  ];

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "85vh" }}>
          <LocalizedDataGrid
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={sponsors}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
            rowHeight={40}
            checkboxSelection
            onCellDoubleClick={handleCellDoubleClick}
          />
        </div>
        <EditSponsorDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          selectedSponsor={selectedSponsor}
          setSelectedSponsor={setSelectedSponsor}
          handleSave={handleSave}
        />
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          setVisibleCreateEdit(true);
        }}
        style={{ position: "absolute", right: SPACING, bottom: SPACING }}
      >
        <AddIcon />
      </Fab>
      <ModalSponsor
        visible={visibleCreateEdit}
        onClose={(refresh) => {
          setVisibleCreateEdit(false);
          if (refresh) {
            store.dispatch(getSponsors());
          }
        }}
      ></ModalSponsor>
    </Grid>
  );
};
