import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomFilter = ({ label, options, item = {}, applyValue }) => {
  const { t } = useTranslation();
  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={`${label}-filter-label`} shrink>{label}</InputLabel>
      <Select
        labelId={`${label}-filter-label`}
        id={`${label}-filter`}
        value={item.value || ''}
        onChange={handleFilterChange}
        displayEmpty
        fullWidth
        label={label}
        renderValue={(selected) => {
          if (selected === '') {
            return t("all");
          }
          return selected;
        }}
      >
        <MenuItem value="">
          {t("all")}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomFilter;