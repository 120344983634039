import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CentersDialog = ({ open, handleClose, selectedProtocol, centers, selectedCenters, handleCheckboxChange, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-centers-dialog-title"
      aria-describedby="add-centers-dialog-description"
      PaperProps={{
        style: { width: "80%", padding: "15px" },
      }}
    >
      <DialogTitle id="add-centers-dialog-title">
        {`${t("addOrRemoveCentersForProtocol")} ${
          selectedProtocol ? selectedProtocol.reference : ""
        }`}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {centers.map((center) => (
            <Grid item xs={12} sm={6} key={center.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckboxChange}
                    name={center.id.toString()}
                    checked={selectedCenters.includes(center.id.toString())}
                  />
                }
                label={center.centerNumber}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          style={{ padding: "16px 88px", margin: "16px" }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          style={{
            padding: "16px 88px",
            margin: "16px",
            color: "#fff",
            backgroundColor: "#1e3179",
          }}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CentersDialog;