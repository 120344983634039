import React from 'react';
import { Grid, TextField, Typography, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PackageDetails = ({ index, packageData }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment key={index}>
      <Grid item xs={3}>
        <Typography variant="body1"> 
          {t("package")} 
           {index + 1}:</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={t("length")}
          type="number"
          value={packageData?.length || ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={t("height")}
          type="number"
          value={packageData?.height || ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={t("width")}
          type="number"
          value={packageData?.width || ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={t("volumetricWeight")}
          value={packageData?.volumetricWeight || ""}
          type="number"
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
    </React.Fragment>
  );
};

export default PackageDetails;