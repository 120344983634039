import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export const Copyright = (props) => {
  const { t } = useTranslation();

  return (
    <div >
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {t("copyright") + " © "}
        <Link color="inherit" href="https://cdlpharma.com/">
          CDL Pharma
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        style={{ margin: 0, padding: 0}}
        {...props}
      >
        <Link color="inherit" href="/legal">
        {t("legalNotices")}
        </Link>
      </Typography>
    </div>
  );
};