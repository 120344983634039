import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const EditStartDateDialog = ({ open, handleClose, dialog, setDialog, handleConfirmDate }) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Dialog
        PaperProps={{
          style: { padding: "20px" },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t("startDateOfProtocol")}</DialogTitle>
        <DialogContent>
          <DatePicker
            value={dayjs(dialog.date)}
            onChange={(newValue) => {
              const dateStr = newValue.format("YYYY-MM-DD");
              setDialog({ ...dialog, date: new Date(dateStr) });
            }}
            TextField={(params) => <TextField {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmDate}
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EditStartDateDialog;