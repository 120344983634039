import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EditQuantityDialog = ({
  open,
  handleClose,
  quantity,
  setQuantity,
  handleSaveEditQuantity
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("editQuantity")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("quantity")}
          type="number"
          value={quantity}
          onChange={(event) => {
            try {
              const value = event.target.value;
              setQuantity(value);
            } catch (error) {
              console.error("Error setting quantity:", error);
            }
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ m: 1, padding: "8px 16px" }}
          onClick={handleSaveEditQuantity}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuantityDialog;