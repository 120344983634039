import React from "react";
import { TextField, Box, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';

const UserInfo = ({ title = "", user = {}, date = "" }) => {
  const { t } = useTranslation();

  return (
    <Box mb={1}>
      <TextField
        value={title}
        InputProps={{
          readOnly: true,
        }}
        inputProps={{
          style: { textAlign: "center" },
        }}
        fullWidth
        style={{ marginBottom: "8px", marginTop: "16px" }}
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label={t("name")}
            value={user?.firstname + " " + user?.name || ""}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center", padding: "10px 0" },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("email")}
            value={user?.email || ""}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: { textAlign: "center", padding: "10px 0" },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("date")}
            value={date}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center", padding: "10px 0" },
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserInfo;