import Swal from "sweetalert2";

// success alert
export function showSuccessAlert(text) {
  return Swal.fire({
    text: text,
    icon: "success",
    timer: 2000,
    timerProgressBar: true,
    confirmButtonText: "OK",
    confirmButtonColor: "#1e3179",
  });
}

export function showLoadingAlert(text) {
  return Swal.fire({
    title: "Please wait",
    text: text,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
}
export function closeLoadingAlert() {
  Swal.close();
}

// info alert
export function showInfoAlert(text) {
  return Swal.fire({
    text: text,
    icon: "info",
    timer: 2000,
    timerProgressBar: true,
    confirmButtonColor: "#1e3179",
  });
}

// warning alert
export function showWarningAlert(text) {
  return Swal.fire({
    text: text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, save",
    cancelButtonText: "No, cancel",
    confirmButtonColor: "#1e3179",
    reverseButtons: true,
  });
}

// error alert
export function showErrorAlert(text) {
  return Swal.fire({
    text: text,
    icon: "error",
    confirmButtonColor: "#1e3179",
  });
}
