import React from 'react';
import StudiesIcon from "@mui/icons-material/QueryStats";
import KitsIcon from "@mui/icons-material/Science";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CentersIcon from "@mui/icons-material/Store";
import SponsorsIcon from "@mui/icons-material/Apartment";
import UsersIcon from "@mui/icons-material/People";
import ProductionsIcon from "@mui/icons-material/Biotech";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const renderTitle = (location, studyCode, centerNumber, centerUser, centerId, t) => {
  let title;

  switch (location.pathname) {
    case "/":
      title = centerUser ? 
        <div style={{ display: "flex", alignItems: "center" }}><ProductionsIcon key="icon" /> {t("inventory")}</div> : <div style={{ display: "flex", alignItems: "center" }}><StudiesIcon key="icon" style={{ marginRight: '8px' }}/> {t("studies")}</div>;
      break;
    case "/legal":
      title = t("legalNotices"); 
      break;
    case "/centers":
      title = [<CentersIcon key="icon"/>, ` ${t("centers")}`];
      break;
    case "/users":
      title = <div style={{ display: "flex", alignItems: "center" }}><UsersIcon key="icon" style={{ marginRight: '8px' }}/> {t("users")}</div>;
      break;
    case "/studies":
      title = <div style={{ display: "flex", alignItems: "center" }}><StudiesIcon key="icon" style={{ marginRight: '8px' }}/> {t("studies")} </div>;
      break;
    case "/study-info":
      title = <div style={{ display: "flex", alignItems: "center" }}><StudiesIcon key="icon" style={{ marginRight: '8px' }}/> {t("studyInfo")} </div>;
      break;
    case "/materials":
      title = [<KitsIcon key="icon" style={{ marginRight: '4px' }}/>, ` ${t("materialList")}`];
      break;
    case "/orders":
      title = <div style={{ display: "flex", alignItems: "center" }}><ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/> {t("orders")}</div>;
      break;
    case "/orders/":
      title = [<ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/>, ` ${t("orders")}`];
      break;
    case "/orders/new":
      title = [<ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/>, ` ${t("createOrder")}`];
      break;
    case "/orders/confirm":
      title = [<ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/>, ` ${t("confirmOrder")}`];
      break;
    case "/shipping":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, t("shippingInformation")];
      break;
    case "/shipping/new":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, t("provideShippingInfo")];
      break;
    case "/shipping/confirm":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, t("confirmShippingInfo")];
      break;
    case "/aor":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, t("aor")];
      break;
    case "/aor-form":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, t("confirmReception")];
      break;
    case "/productions":
      title = [
        <ProductionsIcon key="icon" />,
        centerNumber || centerUser ? ` ${t("inventory")}` : ` ${t("productions")}`,
      ];
      break;
    case "/sponsors":
      title = <div style={{ display: "flex", alignItems: "center" }}><SponsorsIcon key="icon" style={{ marginRight: '8px' }}/> {t("sponsors")}</div>;
      break;
    case "/center-info":
      title = "";
      break;
    case "/order":
      title = [<ShoppingCartIcon key="icon" />, ` ${t("orderInfo")}`];
      break;
    case "/profile":
      title = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <UsersIcon key="icon" style={{ marginRight: '8px' }}/>
          {t("myProfile")}
        </div>
      );
      break;
    default:
      title = t("dashboard");
  }

  // If a center is selected, display studyCode + centerNumber + title
  if (centerNumber) {
    title = (
      <div style={{ display: "flex", alignItems: "center" }}>
        {studyCode && <>
          <StudiesIcon style={{ marginRight: '8px' }} />
          <span style={{ marginRight: '20px' }}>{studyCode}</span>
        </>}
        <CentersIcon style={{ marginRight: '4px' }}/>
        <span style={{ marginRight: '20px' }}> {`${t("center")} ${centerNumber} `}</span>
        {title}
      </div>
    );
  }
  // If only a study is selected, display studyCode + title
  else if (studyCode) {
    title = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <StudiesIcon style={{ marginRight: '8px' }}/>
        <span style={{ marginRight: '20px' }}>{` ${studyCode} `}</span>
        {title}
      </div>
    );
  }
  return title;
};

export default renderTitle;