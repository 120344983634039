import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { frDataGridLocale } from './FrDataGridLocale';

const LocalizedDataGrid = (props) => {
  const { i18n } = useTranslation();

  const localeText = i18n.language === 'fr' ? frDataGridLocale : undefined;

  return (
    <DataGrid
      localeText={localeText}
      
      {...props}
    />
  );
};

export default LocalizedDataGrid;