import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const TransferProductionDialog = ({
  open,
  onClose,
  selectedProduction,
  selectedCenterId,
  setSelectedCenterId,
  centers,
  handleTransferProduction,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        style: { padding: '20px' },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t("transferProduction")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {selectedProduction ? selectedProduction.Material.name : ''}{' '}
        </DialogContentText>
        <DialogContentText>
          {t("reference")}: {selectedProduction ? selectedProduction.reference : ''}
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <DialogContentText>{t("selectCenter")}</DialogContentText>
        <Select
          value={selectedCenterId || 'At CDL'}
          onChange={(e) => setSelectedCenterId(e.target.value)}
        >
          {selectedProduction?.centerId === null && (
            <MenuItem value="At CDL">{t("atCDL")}</MenuItem>
          )}
          {centers.map((center) => (
            <MenuItem key={center.id} value={center.id}>
              {center.centerNumber}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          style={{ padding: '16px', margin: '16px' }}
          onClick={handleTransferProduction}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferProductionDialog;