import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Select, MenuItem, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ProtocolSelectorDialog = ({
  open,
  handleClose,
  protocols,
  selectedProtocol,
  handleProtocolSelect,
  handleConfirm
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { padding: "20px" },
      }}
    >
      <DialogTitle style={{ marginBottom: "10px" }}>
        {t("selectProtocol")}
      </DialogTitle>
      <DialogContent>
        <FormControl style={{ minWidth: 200, marginBottom: 16 }}>
          <Select
            labelId="protocol-select-label"
            value={selectedProtocol || protocols[0]?.id}
            onChange={handleProtocolSelect}
            style={{ paddingRight: 24 }}
          >
            {protocols.map((protocol) => (
              <MenuItem key={protocol.id} value={protocol.id}>
                {protocol.reference}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProtocolSelectorDialog;