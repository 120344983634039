import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const EditSponsorDialog = ({
  open,
  handleClose,
  selectedSponsor,
  setSelectedSponsor,
  handleSave,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        style: { padding: "20px" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("editSponsor")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("name")}
          type="text"
          fullWidth
          value={selectedSponsor?.name || ""}
          onChange={(e) =>
            setSelectedSponsor({ ...selectedSponsor, name: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label={t("address")}
          type="text"
          fullWidth
          value={selectedSponsor?.address || ""}
          onChange={(e) =>
            setSelectedSponsor({
              ...selectedSponsor,
              address: e.target.value,
            })
          }
        />
        <TextField
          margin="dense"
          label={t("city")}
          type="text"
          fullWidth
          value={selectedSponsor?.city || ""}
          onChange={(e) =>
            setSelectedSponsor({ ...selectedSponsor, city: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label={t("country")}
          type="text"
          fullWidth
          value={selectedSponsor?.country || ""}
          onChange={(e) =>
            setSelectedSponsor({
              ...selectedSponsor,
              country: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          {t("cancel")}
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary">
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSponsorDialog;
