import React, { useEffect, useState } from "react";
import { getAuditLogs, getHttpRequests } from "../../../services/auditService";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { store } from "../../../store";
import { useSelector } from "react-redux";
import { getUsers } from "../../../redux/actions/user";
import { FormControl, InputLabel } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ContentCellRenderer from "../../ui/ContentCellRenderer";

export const ScreenAudit = () => {
  const { t } = useTranslation();
  const users = useSelector((state) => state.user.users);
  const [auditLogs, setAuditLogs] = useState([]);
  const [httpRequests, setHttpRequests] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [page,] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("All");
  const [filter, setFilter] = useState(false);
  const [selectedTable, setSelectedTable] = useState("studies");
  const [tabIndex, setTabIndex] = useState(0);

  const handleFilter = () => {
    setFilter(!filter);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchAuditLogsData = async () => {
      const formattedStartDate = startDate
        ? new Date(startDate).toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? new Date(endDate).toISOString().split("T")[0]
        : "";

      const data = await getAuditLogs(selectedTable, {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        userId: userId !== "All" ? userId : undefined,
      });
      setAuditLogs(Array.isArray(data) ? data : []);
    };

    fetchAuditLogsData().catch(console.error);
  }, [selectedTable, filter, startDate, endDate, userId]);

  useEffect(() => {
    const fetchHttpRequestsData = async () => {
      const formattedStartDate = startDate
        ? new Date(startDate).toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? new Date(endDate).toISOString().split("T")[0]
        : "";

      const data = await getHttpRequests({
        page,
        pageSize,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        userId: userId !== "All" ? userId : undefined,
      });
      setHttpRequests(Array.isArray(data) ? data : []);
    };

    fetchHttpRequestsData().catch(console.error);
  }, [page, pageSize, filter, startDate, endDate, userId]);

  // Fetch users
  useEffect(() => {
    store.dispatch(getUsers());
  }, []);

  const auditColumns = [
    {
      field: "user_id",
      headerName: t("user"),
      width: 250,
      editable: true,
      valueGetter: (params) => {
        if (params.value === "Unauthenticated user") {
          return t("unauthenticatedUser");
        }

        const userId = parseInt(params.value, 10);
        const user = users.find((user) => user.id === userId);

        if (user) {
          return `${user.name.toUpperCase()} ${user.firstname}`;
        }
        return t("unknownUser");
      },
    },
    {
      field: "datetime",
      headerName: t("dateTime"),
      width: 180,
      editable: true,
      type: "dateTime",
      valueFormatter: ({ value }) => new Date(value).toLocaleString(),
    },
    {
      field: "action",
      headerName: t("action"),
      width: 150,
      editable: true,
      valueGetter: (params) => {
        switch (params.value) {
          case 'CREATE':
            return t('create');
          case 'UPDATE':
            return t('update');
          case 'DELETE':
            return t('delete');
          default:
            return params.value;
        }
      },
    },
    {
      field: "content",
      headerName: t("content"),
      minWidth: 600,
      editable: true,
      renderCell: (params) => <ContentCellRenderer value={params.value} />,
      autoHeight: true,
    },
  ];

  const httpColumns = [
    {
      field: "user",
      headerName: t("user"),
      width: 250,
      editable: true,
      valueGetter: (params) => {
        if (params.value === "Unauthenticated user") {
          return t("unauthenticatedUser");
        }

        const userId = parseInt(params.value, 10);
        const user = users.find((user) => user.id === userId);

        if (user) {
          return `${user.name.toUpperCase()} ${user.firstname}`;
        }
        return t("unknownUser");
      },
    },
    {
      field: "ip",
      headerName: t("ipAddress"),
      width: 200,
      editable: true,
    },
    {
      field: "method",
      headerName: t("method"),
      width: 100,
      editable: true,
    },
    {
      field: "url",
      headerName: t("url"),
      width: 300,
      editable: true,
    },
    {
      field: "datetime",
      headerName: t("dateTime"),
      width: 180,
      editable: true,
      type: "dateTime",
      valueFormatter: ({ value }) => new Date(value).toLocaleString(),
    },
  ];

  return (
    <Grid item xs={12}>
      <Paper style={{ padding: "16px" }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={t("auditLogs")} />
          <Tab label={t("httpRequests")} />
        </Tabs>
        {tabIndex === 0 && (
          <>
            <div style={{ display: "flex", gap: "8px", marginTop: "16px" }}>
              <FormControl variant="outlined">
                <InputLabel id="table-select-label">{t("table")}</InputLabel>
                <Select
                  label={t("table")}
                  value={selectedTable}
                  onChange={(e) => setSelectedTable(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="studies">{t("studies")}</MenuItem>
                  <MenuItem value="centers">{t("centers")}</MenuItem>
                  <MenuItem value="aors">{t("aors")}</MenuItem>
                  <MenuItem value="materials">{t("materials")}</MenuItem>
                  <MenuItem value="orders">{t("orders")}</MenuItem>
                  <MenuItem value="productions">{t("productions")}</MenuItem>
                  <MenuItem value="shipments">{t("shipments")}</MenuItem>
                  <MenuItem value="sponsors">{t("sponsors")}</MenuItem>
                  <MenuItem value="supplies">{t("supplies")}</MenuItem>
                  <MenuItem value="users">{t("users")}</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel id="user-select-label">{t("user")}</InputLabel>
                <Select
                  label={t("user")}
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem key="All" value="All">
                    {t("allUsers")}
                  </MenuItem>
                  <MenuItem key="Unauthenticated user" value="Unauthenticated user">
                    {t("unauthenticatedUser")}
                  </MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {`${user.name}, ${user.firstname}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label={t("startDate")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                label={t("endDate")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Button
                variant="contained"
                style={{ padding: "16px" }}
                onClick={() => handleFilter()}
              >
                {t("filter")}
              </Button>
            </div>
            <div style={{ width: "100%", height: "85vh", marginTop: "16px" }}>
              <DataGrid
                rows={auditLogs}
                columns={auditColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={100} 
                pagination
              />
            </div>
          </>
        )}
        {tabIndex === 1 && (
          <>
            <div style={{ display: "flex", gap: "8px", marginTop: "16px" }}>
              <FormControl variant="outlined">
                <InputLabel id="user-select-label">{t("user")}</InputLabel>
                <Select
                  label={t("user")}
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem key="All" value="All">
                    {t("allUsers")}
                  </MenuItem>
                  <MenuItem key="Unauthenticated user" value="Unauthenticated user">
                    {t("unauthenticatedUser")}
                  </MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {`${user.name}, ${user.firstname}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label={t("startDate")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                label={t("endDate")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Button
                variant="contained"
                style={{ padding: "16px" }}
                onClick={() => handleFilter()}
              >
                {t("filter")}
              </Button>
            </div>
            <div style={{ width: "100%", height: "85vh", marginTop: "16px" }}>
              <DataGrid
                rows={httpRequests}
                columns={httpColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={40}
                pagination
              />
            </div>
          </>
        )}
      </Paper>
    </Grid>
  );
};