import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { createStudy } from "../../redux/actions/study";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { store } from "../../store";
import Grid from "@mui/material/Grid";
import { getSponsors } from "../../redux/actions/sponsor";
import { showSuccessAlert } from "../ui/utils/AlertUtils";
import { useTranslation } from "react-i18next";

export const ModalStudy = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const [cdlStudyCode, setCdlStudyCode] = React.useState("");
  const [sponsorStudyCode, setSponsorStudyCode] = React.useState("");
  const [status, setStatus] = React.useState("active");
  const [sponsorId, setSponsorId] = React.useState("");
  const isLoading = useSelector((state) => state.study.isLoadingStudy);
  const created = useSelector((state) => state.study.createdStudy);
  const sponsors = useSelector((state) => state.sponsor.sponsors);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (cdlStudyCode && sponsorStudyCode && status && sponsorId) {
      setCdlStudyCode("");
      setSponsorStudyCode("");
      setStatus("");
      setSponsorId("");
      store.dispatch(
        createStudy({
          cdlStudyCode: cdlStudyCode,
          sponsorStudyCode: sponsorStudyCode,
          status: status,
          sponsorId: sponsorId,
        })
      );
    }
  };

  React.useEffect(() => {
    if (created) {
      showSuccessAlert(t("studyCreatedSuccess"));
      onClose(true);
    }
  }, [created, onClose, t]);

  React.useEffect(() => {
    store.dispatch(getSponsors());
  }, []);

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          flexGrow: 1,
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              {t("createStudy")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              {t("pleaseIndicateFields")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-ref-sponsor"
                value={sponsorStudyCode}
                onChange={(event) => {
                  setSponsorStudyCode(event.target.value);
                }}
                id="outlined-required"
                label={t("refSponsorCode")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-ref-cdl"
                value={cdlStudyCode}
                onChange={(event) => {
                  setCdlStudyCode(event.target.value);
                }}
                id="outlined-required"
                label={t("refCdlCode")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-status">{t("status")}</InputLabel>
              <Select
                labelId="select-status"
                id="select-status"
                value={status}
                name="selectfield"
                label={t("status")}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
              >
                <MenuItem value="active">{t("active")}</MenuItem>
                <MenuItem value="planned">{t("planned")}</MenuItem>
                <MenuItem value="completed">{t("completed")}</MenuItem>
                <MenuItem value="cancelled">{t("cancelled")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-sponsor">{t("sponsor")}</InputLabel>
              <Select
                labelId="select-sponsor"
                id="select-sponsor"
                value={sponsorId}
                name="select-sponsor"
                label={t("sponsor")}
                onChange={(event) => {
                  setSponsorId(event.target.value);
                }}
              >
                {sponsors.map((sponsor) => (
                  <MenuItem key={sponsor.id} value={sponsor.id}>
                    {sponsor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, padding: 2 }}
            >
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};