import React from "react";
import { useTranslation } from "react-i18next";

const LegalNotice = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>{t("legalNotice")}</h2>
      <div>
       <h3>{t("ownershipTitle")}</h3>
        <p style={{ fontSize: "0.8rem" }}>
          {t("ownershipContent")}
        </p>

        <h3>{t("technicalInformationTitle")}</h3>
        <p style={{ fontSize: "0.8rem" }}>
          {t("technicalInformationContent")}
        </p>

        <h3>{t("siteContentTitle")}</h3>
        <p style={{ fontSize: "0.8rem" }}>
          {t("siteContentContent")}
        </p>

        <h3>{t("hypertextLinkTitle")}</h3>
        <p style={{ fontSize: "0.8rem" }}>
          {t("hypertextLinkContent")}
        </p>

        <h3>{t("authorizationToReproduceTitle")}</h3>
        <p style={{ fontSize: "0.8rem" }}>
          {t("authorizationToReproduceContent")}
        </p>
      </div>

      <h2 style={{ textAlign: "center", marginTop: "10rem" }}>{t("privacyPolicy")}</h2>
      <p style={{ fontSize: "0.8rem" }}>
      {t("privacyPolicyContent")}
      </p>
      <h3>{t("consentTitle")}</h3>
      <p style={{ fontSize: "0.8rem" }}>
        {t("consentContent")}
      </p>
      <h3>{t("informationWeCollectTitle")}</h3>
      <p style={{ fontSize: "0.8rem" }}>
        {t("informationWeCollectContent")}
      </p>
      <h3>{t("howWeUseYourInformationTitle")}</h3>
      <p style={{ fontSize: "0.8rem" }}>
        {t("howWeUseYourInformationContent")}
      </p>
      <h3>{t("logFilesTitle")}</h3>
      <p style={{ fontSize: "0.8rem" }}>
        {t("logFilesContent")}
      </p>
      <h3>{t("cookiesTitle")}</h3>
      <p style={{ fontSize: "0.8rem" }}>
        {t("cookiesContent")}
      </p>
      <h3>{t("gdprDataProtectionRightsTitle")}</h3>
      <p style={{ fontSize: "0.8rem" }}>
        {t("gdprDataProtectionRightsContent")}
      </p>
      <h3>{t("exerciseYourRightsTitle")}</h3>
      <p style={{ fontSize: "0.8rem" }}>
        {t("exerciseYourRightsContent")}
      </p>
    </div>
  );
};

export default LegalNotice;
