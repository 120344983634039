import { Grid, Paper, Box } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStudyInfo } from "../../../redux/actions/study";
import { store } from "../../../store";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  addCenterToProtocol,
  deleteProtocol,
  getCentersForProtocol,
  removeCenterFromProtocol,
  updateProtocolStartDate,
} from "../../../services/protocolService";
import Swal from "sweetalert2";
import {
  showErrorAlert,
  showInfoAlert,
  showSuccessAlert,
} from "../../ui/utils/AlertUtils";
import { useTranslation } from "react-i18next";
import NewProtocolDialog from "../../ui/dialogs/NewProtocolDialog";
import EditStartDateDialog from "../../ui/dialogs/EditStartDateProtocol";
import CentersForProtocolDialog from "../../ui/dialogs/CentersForProtocolDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';


export const ScreenStudyInfo = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const studyId = location.state.studyId;
  const study = useSelector((state) => state.study.study);
  const isLoading = useSelector((state) => state.study.isLoading);
  const sponsorManagers = study?.sponsors?.filter(
    (user) => user.role !== "monitor"
  );
  const protocols = study?.protocols;
  const centers = useSelector((state) => state.center.centers);
  const [centersDialogOpen, setCentersDialogOpen] = React.useState(false);
  const [dialog, setDialog] = React.useState({
    open: false,
    date: null,
    protocolId: null,
  });
  const [selectedCenters, setSelectedCenters] = React.useState([]);
  const [initiallySelectedCenters, setInitiallySelectedCenters] =
    React.useState([]);
  const [removedCenters, setRemovedCenters] = React.useState([]);
  const [currentProtocolId, setCurrentProtocolId] = React.useState(null);
  const [selectedProtocol, setSelectedProtocol] = React.useState(null);
  const [, setAddedCenters] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [protocolChanged, setProtocolChanged] = React.useState(false);
  const monitors = study?.Centers
    ? study.Centers.reduce((acc, center) => {
        const newAcc = [...acc];
        center.monitors.forEach((monitor) => {
          const existingMonitor = newAcc.find((m) => m.id === monitor.id);
          if (existingMonitor) {
            existingMonitor.centers.push({
              id: center.id,
              centerNumber: center.centerNumber,
            });
          } else {
            newAcc.push({
              id: monitor.id,
              name: monitor.name,
              firstname: monitor.firstname,
              email: monitor.email,
              phoneNumber: monitor.phoneNumber,
              centers: [{ id: center.id, centerNumber: center.centerNumber }],
            });
          }
        });
        return newAcc;
      }, [])
    : [];

  const handleConfirmDate = async () => {
    try {
      await updateProtocolStartDate(dialog.protocolId, dialog.date);
      showSuccessAlert(t("protocolStartDateUpdatedSuccess"));
      setProtocolChanged(true);
      setDialog({ open: false, date: null, protocolId: null });
    } catch (error) {
      console.error(
        "An error occurred while updating the study start date:",
        error
      );
      showErrorAlert(t("protocolStartDateUpdateFailed"));
    }
  };

  const handleNewProtocol = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog({ open: false, date: null, protocolId: null });
  };

  const handleCentersDialogOpen = async (protocolId) => {
    try {
      const linkedCenters = await getCentersForProtocol(protocolId);
      const linkedCenterIds = linkedCenters.map((center) =>
        center.id.toString()
      );
      setSelectedCenters(linkedCenterIds);
      setInitiallySelectedCenters(linkedCenterIds);
    } catch (error) {
      console.error("Failed to fetch centers for protocol", error);
    }
    setCentersDialogOpen(true);
  };

  const handleCentersDialogClose = () => {
    setCentersDialogOpen(false);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedCenters([...selectedCenters, event.target.name]);
    } else {
      setSelectedCenters(
        selectedCenters.filter((centerId) => centerId !== event.target.name)
      );
      if (initiallySelectedCenters.includes(event.target.name)) {
        setRemovedCenters((prevRemovedCenters) => [
          ...prevRemovedCenters,
          event.target.name,
        ]);
      }
    }
  };
  
  const handleConfirm = async () => {
    if (selectedCenters.length === 0 && removedCenters.length === 0) {
      handleCentersDialogClose();
      showInfoAlert(t("noChangesMade"));
    } else {
      for (const centerId of selectedCenters) {
        if (!initiallySelectedCenters.includes(centerId)) {
          try {
            const response = await addCenterToProtocol(
              currentProtocolId,
              centerId
            );
            if (response) {
              setProtocolChanged(true);
              // Update the addedCenters state
              setAddedCenters((prevAddedCenters) => [
                ...prevAddedCenters,
                centerId,
              ]);
            }
          } catch (error) {
            showErrorAlert(t("failedToAddCenter"));
          }
        }
      }
      for (const centerId of removedCenters) {
        removeCenterFromProtocol(currentProtocolId, centerId);
      }
      if (selectedCenters.length > 0 || removedCenters.length > 0) {
        showSuccessAlert(t("changesSaved"));
      }
      setSelectedCenters([]); // Clear selectedCenters
      setRemovedCenters([]); // Clear removedCenters
    }
    handleCentersDialogClose();
  };
  
  // Update the protocol start date
  const handleCellEditCommit = async (params) => {
    if (params.field === "startDate") {
      const newDate = params.value;
      const protocolId = params.id;
  
      try {
        await updateProtocolStartDate(protocolId, newDate);
        showSuccessAlert(t("protocolStartDateUpdatedSuccess"));
      } catch (error) {
        console.error(
          "An error occurred while updating the study start date:",
          error
        );
        showErrorAlert(t("protocolStartDateUpdateFailed"));
      }
    }
  };

  // cell to add Centers to protocol
  const renderEditCell = (params) => {
    const onClick = () => {
      const protocolId = params.row.id;
      setCurrentProtocolId(protocolId);
      handleCentersDialogOpen(protocolId);

      const selectedProtocol = protocols.find(
        (protocol) => protocol.id === protocolId
      );
      setSelectedProtocol(selectedProtocol); // Update the state
    };

    return (
      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={onClick}>
          {t("manageCenters")}
        </Button>
      </Box>
    );
  };
  //sponsorManagers
  const columns = [
    { field: "name", headerName: t("name"), flex: 1 },
    { field: "firstname", headerName: t("firstname"), flex: 1 },
    { field: "email", headerName: t("email"), flex: 1 },
    { field: "phoneNumber", headerName: t("phoneNumber"), flex: 1 },
  ];

  const monitorColumns = [
    ...columns,
    {
      field: "centers",
      headerName: t("centers"),
      flex: 1,
      valueGetter: (params) =>
        params.row.centers.map((center) => center.centerNumber).join(", "),
    },
  ];

  const protocolColumns = [
    { field: "reference", headerName: t("reference"), flex: 0.4 },
    {
      field: "startDate",
      headerName: t("startDate"),
      flex: 0.4,
      renderCell: (params) => (
        <div
          onDoubleClick={() =>
            setDialog({ open: true, date: params.value, protocolId: params.id })
          }
        >
          {dayjs(params.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "",
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: renderEditCell,
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = async () => {
          const id = params.row.id;
          Swal.fire({
            title: t("warningAlert"),
            text: t("deleteProtocolText"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1e3179",
            confirmButtonText: t("deleteConfirmButton"),
            cancelButtonText: t("cancel"),
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                const protocolDeleted = await deleteProtocol(id);
                if (protocolDeleted) {
                  showSuccessAlert(t("deleteProtocolSuccess"));
                  setProtocolChanged(true);
                }
              } catch (error) {
                showErrorAlert(t("deleteProtocolFailed"));
                console.error("Failed to delete protocol", error);
              }
            }
          });
        };

        return (
          <Tooltip title={t("delete")}>
      <Button onClick={onClick} color="secondary" >
        <DeleteIcon />
      </Button>
    </Tooltip>
        );
      },
    },
  ];

  React.useEffect(() => {
    if (protocolChanged) {
      setProtocolChanged(false);
    }
    store.dispatch(getStudyInfo(studyId));
  }, [studyId, protocolChanged]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
      >
        <div style={{ width: "100%" }}>
          <Box mx={4} mt={8}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label={t("cdlStudyCode")}
                  value={study?.cdlStudyCode || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={t("sponsorStudyCode")}
                  value={study?.sponsorStudyCode || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Sponsor"
                  value={study?.sponsor?.name || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={4}>
            <TextField
              value="SPONSOR MANAGERS"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {!isLoading && sponsorManagers && sponsorManagers?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={sponsorManagers}
                columns={columns}
                loading={isLoading}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20]}
                checkboxSelection
                hideFooter
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {t("noSponsorManagers")}
              </div>
            )}
          </Box>
          <Box m={4}>
            <TextField
              value={t("monitors").toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {!isLoading && monitors && monitors?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={monitors}
                columns={monitorColumns}
                loading={isLoading}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20]}
                checkboxSelection
                hideFooter
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {t("noMonitors")}
              </div>
            )}
          </Box>

          <Box m={4}>
            <TextField
              value={t("protocols").toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {!isLoading && protocols && protocols?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={protocols}
                columns={protocolColumns}
                loading={isLoading}
                pageSizeOptions={[10, 20]}
                hideFooter
                onCellEditCommit={handleCellEditCommit}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {t("noAdditionalProtocols")}
              </div>
            )}
            {/* EDIT THE START DATE OF A PROTOCOL */}
            <EditStartDateDialog
              open={dialog.open}
              handleClose={handleClose}
              dialog={dialog}
              setDialog={setDialog}
              handleConfirmDate={handleConfirmDate}
            />
            <Box display="flex" justifyContent="center">
              {/* ADD A NEW PROTOCOL */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewProtocol}
                style={{ padding: "16px 88px", marginTop: "16px" }}
              >
                {t("addNewProtocol")}
              </Button>
              <NewProtocolDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                setProtocolChanged={setProtocolChanged}
                studyId={studyId}
              />
            </Box>
            {/* ADD CENTERS TO A PROTOCOL  */}
            <CentersForProtocolDialog
              open={centersDialogOpen}
              handleClose={handleCentersDialogClose}
              selectedProtocol={selectedProtocol}
              centers={centers}
              selectedCenters={selectedCenters}
              handleCheckboxChange={handleCheckboxChange}
              handleConfirm={handleConfirm}
            />
          </Box>
        </div>
      </Paper>
    </Grid>
  );
};
