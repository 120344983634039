import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import * as React from "react";
import Fab from "@mui/material/Fab";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import { getUsers, updateUser } from "../../../redux/actions/user";
import { editUser, deactivateUser } from "../../../redux/actions/user";
import { store } from "../../../store";
import { ModalUser } from "../../modals/User";
import { getStudies, getStudiesBySponsor } from "../../../redux/actions/study";
import { getCenters } from "../../../redux/actions/center";
import Button from "@mui/material/Button";
import { showSuccessAlert, showWarningAlert } from "../../ui/utils/AlertUtils";
import AssignDialog from "../../ui/dialogs/AssignDialog";
import getUniqueOptions from "../../table/getUniqueOptions";
import CustomFilter from "../../table/CustomFilter";
import { useTranslation } from "react-i18next";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";
import EditUserDialog from "../../ui/dialogs/EditUserDialog";

const SPACING = 50;

export const ScreenUsers = (props) => {
  const { t } = useTranslation();
  const users = useSelector((state) => state.user.users);
  const isLoading = useSelector((state) => state.user.isLoadingUsers);
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [visibleEditDialog, setVisibleEditDialog] = React.useState(false);
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const studies = useSelector((state) => state.study.studies);
  const centers = useSelector((state) => state.center.centers);
  const [selectedStudies, setSelectedStudies] = React.useState([]);
  const [selectedCenters, setSelectedCenters] = React.useState([]);
  const [name, setName] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [userUpdated, setUserUpdated] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [organizationOptions, setOrganizationOptions] = React.useState([]);
  const [roleOptions, setRoleOptions] = React.useState([]);

  React.useEffect(() => {
    if (users.length > 0) {
      setOrganizationOptions(getUniqueOptions(users, "organization"));
      setRoleOptions(getUniqueOptions(users, "role"));
    }
  }, [users]);

  const columns = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      renderCell: (params) => params.value.toUpperCase(),
    },
    { field: "firstname", headerName: t("firstname"), flex: 1 },
    {
      field: "organization",
      headerName: t("organization"),
      flex: 1,
      filterable: true,
      renderCell: (params) => params.value,
      filterOperators: [
        {
          label: t("organization"),
          value: "organization",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter {...props} options={organizationOptions} />
          ),
        },
      ],
    },
    {
      field: "role",
      headerName: t("role"),
      flex: 1,
      renderCell: (params) =>
        params.value.charAt(0).toUpperCase() +
        params.value.slice(1).toLowerCase(),
      filterable: true,
      filterOperators: [
        {
          label: t("role"),
          value: "role",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter {...props} options={roleOptions} />
          ),
        },
      ],
    },
    { field: "email", headerName: t("email"), flex: 1.4 },
    { field: "phoneNumber", headerName: t("phoneNumber"), flex: 0.8 },
    {
      field: "status",
      headerName: t("account"),
      flex: 0.8,
      renderCell: (params) => {
        return params.value === "inactive" ? t("deactivated") : params.value;
      },
    },
  ];

  const handleDeactivateUser = () => {
    if (selectedRows.length > 0) {
      const selectedUserNames = selectedRows
        .map((id) => {
          const user = users.find((user) => user.id === id);
          return `${user.firstname} ${user.name}`;
        })
        .join(", ");

      const alertMessage = `You are about to deactivate the account${
        selectedRows.length > 1 ? "s" : ""
      } of ${selectedUserNames}. Do you really want to proceed?`;

      showWarningAlert(alertMessage).then((result) => {
        if (result.isConfirmed) {
          const deactivationPromises = selectedRows.map((id) =>
            store.dispatch(deactivateUser(id))
          );
          Promise.all(deactivationPromises).then(() => {
            showSuccessAlert("Account deactivated successfully");
            setUserUpdated(true);
          });
        }
      });
    }
  };

  const handleCellDoubleClick = (params, event) => {
    if (params.field === "name") {
      setVisibleEditDialog(true);
      setSelectedUser(params.row);
      setName(params.row.name);
      setFirstname(params.row.firstname);
      setPhone(params.row.phoneNumber);
      setEmail(params.row.email);
    }
  };

  const onClose = () => {
    setVisibleEditDialog(false);
  };

  const handleSave = async () => {
    await store.dispatch(
      editUser(selectedUser.id, { name, firstname, phoneNumber: phone, email })
    );
    setVisibleEditDialog(false);
    setUserUpdated(true);
    showSuccessAlert("User updated successfully");
  };

  const handleStudyChange = (event, studyId) => {
    if (event.target.checked) {
      setSelectedStudies((prev) => [...prev, studyId]);
    } else {
      setSelectedStudies((prev) => prev.filter((id) => id !== studyId));
    }
  };

  const handleCenterChange = (event, centerId) => {
    if (event.target.checked) {
      setSelectedCenters((prev) => [...prev, centerId]);
    } else {
      setSelectedCenters((prev) => prev.filter((id) => id !== centerId));
    }
  };

  const handleConfirm = () => {
    let updates = {};

    if (selectedUser.role === "manager") {
      updates = { sponsorStudies: selectedStudies };
    } else if (selectedUser.role === "monitor") {
      updates = { centerMonitors: selectedCenters };
    } else if (selectedUser.organization === "CDL") {
      updates = { employeeStudies: selectedStudies };
    }

    store.dispatch(
      updateUser({
        id: selectedUser.id,
        updates,
      })
    );

    setVisibleDialog(false);
    showSuccessAlert("User updated successfully");
  };

  // Set selected studies when user is selected
  React.useEffect(() => {
    if (
      selectedUser?.organization === "Sponsor" &&
      selectedUser?.sponsorStudies
    ) {
      setSelectedStudies(selectedUser.sponsorStudies.map((study) => study.id));
    }
    if (selectedUser?.organization === "CDL" && selectedUser?.employeeStudies) {
      setSelectedStudies(selectedUser.employeeStudies.map((study) => study.id));
    }
  }, [selectedUser]);

  // Set selected centers when user is selected
  React.useEffect(() => {
    if (selectedUser?.centerMonitors) {
      setSelectedCenters(
        selectedUser.centerMonitors.map((center) => center.id)
      );
    }
  }, [selectedUser]);

  // Fetch users
  React.useEffect(() => {
    store.dispatch(getUsers());
  }, []);

  React.useEffect(() => {
    if (userUpdated) {
      store.dispatch(getUsers());
      setUserUpdated(false);
    }
  }, [userUpdated]);

  // Fetch studies and centers when dialog is opened
  React.useEffect(() => {
    if (selectedUser?.role === "manager") {
      store.dispatch(getStudiesBySponsor(selectedUser.sponsorId));
    } else if (
      selectedUser?.role === "monitor" &&
      selectedUser.centerMonitors.length > 0
    ) {
      const studyId = selectedUser.centerMonitors[0].studyId; // retrieve studyId from center
      store.dispatch(getCenters(studyId));
    } else if (
      selectedUser?.role === "employee" &&
      selectedUser.organization === "CDL"
    ) {
      store.dispatch(getStudies());
    }
  }, [visibleDialog, selectedUser]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "90vh" }}>
          <LocalizedDataGrid
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={users}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            rowHeight={30}
            pageSizeOptions={[50, 100]}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            onCellDoubleClick={(params, event) => {
              if (
                params.field === "organization" &&
                (params.value === "Sponsor" ||
                  (params.value === "CDL" && params.row.role === "employee"))
              ) {
                setVisibleDialog(true);
                setSelectedUser(params.row);
              } else if (params.field === "name") {
                handleCellDoubleClick(params, event);
              }
            }}
          />
          {selectedRows.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: "16px 28px", margin: "16px" }}
              onClick={handleDeactivateUser}
            >
              {t("deactivateAccounts")}
            </Button>
          )}
        </div>
        <EditUserDialog
          open={visibleEditDialog}
          onClose={onClose}
          name={name}
          setName={setName}
          firstname={firstname}
          setFirstname={setFirstname}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          handleSave={handleSave}
        />
        <AssignDialog
      open={visibleDialog}
      handleClose={() => setVisibleDialog(false)}
      selectedUser={selectedUser}
      studies={studies}
      centers={centers}
      selectedStudies={selectedStudies}
      selectedCenters={selectedCenters}
      handleStudyChange={handleStudyChange}
      handleCenterChange={handleCenterChange}
      handleConfirm={handleConfirm}
    />
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setVisibleCreateEdit(true)}
        style={{ position: "absolute", right: SPACING, bottom: SPACING }}
      >
        <AddIcon />
      </Fab>
      <ModalUser
        visible={visibleCreateEdit}
        onClose={(refresh) => {
          setVisibleCreateEdit(false);
          if (refresh) {
            store.dispatch(getUsers());
          }
        }}
      ></ModalUser>
    </Grid>
  );
};
