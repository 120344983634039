import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const DestructionDateDialog = ({
  open,
  onClose,
  destructionDate,
  handleDateChange,
  handleConfirmDestruction,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        style: { padding: "20px" },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t("selectDestructionDate")}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            value={destructionDate || dayjs()}
            onChange={handleDateChange}
            maxDate={dayjs()}
            TextField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          style={{ padding: "16px", margin: "16px" }}
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ padding: "16px", margin: "16px" }}
          onClick={handleConfirmDestruction}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DestructionDateDialog;
