import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const UpdateCenterAddressDialog = ({
  open,
  handleClose,
  hospital,
  setHospital,
  ward,
  setWard,
  building,
  setBuilding,
  street,
  setStreet,
  floor,
  setFloor,
  zipcode,
  setZipcode,
  city,
  setCity,
  country,
  setCountry,
  handleSaveAddress
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { padding: "20px" },
      }}
    >
      <DialogTitle id="form-dialog-title">
        {t("updateCenterAddress")}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("hospital")}
          value={hospital}
          fullWidth
          onChange={(e) => setHospital(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("ward")}
          value={ward}
          fullWidth
          onChange={(e) => setWard(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("building")}
          value={building}
          fullWidth
          onChange={(e) => setBuilding(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("street")}
          value={street}
          fullWidth
          onChange={(e) => setStreet(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("floor")}
          value={floor}
          fullWidth
          onChange={(e) => setFloor(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("zipcode")}
          value={zipcode}
          fullWidth
          onChange={(e) => setZipcode(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("city")}
          value={city}
          fullWidth
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("country")}
          value={country}
          fullWidth
          onChange={(e) => setCountry(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSaveAddress}
          color="primary"
          variant="contained"
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCenterAddressDialog;