import { FormControl, TextField, FormHelperText } from "@mui/material";
import { Button, Modal, Box } from "@mui/material";
import { IconButton, Typography, InputLabel } from "@mui/material";
import { Select, MenuItem, OutlinedInput, Grid } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import * as React from "react";
import { createCenter } from "../../redux/actions/center";
import { store } from "../../store";
import countries from "i18n-iso-countries";
import { showSuccessAlert } from "../ui/utils/AlertUtils";
import { getProtocols } from "../../services/protocolService";
import { useTranslation } from "react-i18next";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryList = countries.getNames("en", { select: "official" });

export const ModalCenter = ({ visible, onClose, studyId, studyCode }) => {
  const { t } = useTranslation();
  const [centerNumber, setCenterNumber] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [status, setStatus] = React.useState("active");
  const [contactFirstName, setContactFirstName] = React.useState("");
  const [contactLastName, setContactLastName] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const isLoading = useSelector((state) => state.center.isLoadingCenter);
  const created = useSelector((state) => state.center.createdCenter);
  const [errors, setErrors] = React.useState({});
  const [protocols, setProtocols] = React.useState([]);
  const [selectedProtocolId, setSelectedProtocolId] = React.useState("");

  const handleProtocolChange = (event) => {
    setSelectedProtocolId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newErrors = {};

    if (!centerNumber.trim()) newErrors.centerNumber = t("fieldRequired");
    if (!city.trim()) newErrors.city = t("cityRequired");
    if (!country.trim()) newErrors.country = t("countryRequired");
    if (!contactFirstName.trim()) newErrors.contactFirstName = t("contactFirstNameRequired");
    if (!contactLastName.trim()) newErrors.contactLastName = t("contactLastNameRequired");
    if (!contactEmail.trim()) newErrors.contactEmail = t("contactEmailRequired");
    if (!contactPhone.trim()) newErrors.contactPhone = t("contactPhoneRequired");
    if (!selectedProtocolId) newErrors.selectedProtocolId = t("protocolRequired");

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      store.dispatch(
        createCenter({
          centerNumber: centerNumber,
          city: city,
          country: country,
          status: status,
          contactName: `${contactLastName}, ${contactFirstName}`,
          contactEmail: contactEmail,
          contactPhone: contactPhone,
          studyId: studyId,
          protocolId: selectedProtocolId,
        })
      );
    }
  };

  React.useEffect(() => {
    if (created) {
      showSuccessAlert(t("centerCreatedSuccess"));
            // Reset form fields
      setCenterNumber("");
      setCity("");
      setCountry("");
      setStatus("active");
      setContactFirstName("");
      setContactLastName("");
      setContactEmail("");
      setContactPhone("");
      setErrors({});
      setSelectedProtocolId("");
      onClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [created, onClose]);

  React.useEffect(() => {
    const fetchProtocols = async () => {
      const protocols = await getProtocols(studyId);
      setProtocols(protocols);
    };

    fetchProtocols();
  }, [studyId]);

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
            {t("createCenter")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
            {t("pleaseIndicateFields")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormControl fullWidth>
                <TextField
                  required
                  name="centerNumber"
                  value={centerNumber}
                  onChange={(event) => {
                    setCenterNumber(event.target.value);
                  }}
                  id="input-centerNumber"
                  label={t("centerNumber")}
                  type="text"
                  error={!!errors.centerNumber}
                  helperText={errors.centerNumber}
                />
              </FormControl>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="input-city"
                label={t("city")}
                value={city}
                onChange={(event) => setCity(event.target.value)}
                error={!!errors.city}
                helperText={errors.city}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.country}>
              <InputLabel id="select-country"> {t("country")}
              </InputLabel>
              <Select
                labelId="select-country"
                id="select-country"
                value={country}
                onChange={(event) => setCountry(event.target.value)}
                name="select-country"
                label={t("country")}
              >
                {Object.entries(countryList).map(([code, name]) => (
                  <MenuItem key={code} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.country}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="input-contact-first-name"
                label={t("contactFirstName")}
                value={contactFirstName}
                onChange={(event) => setContactFirstName(event.target.value)}
                error={!!errors.contactFirstName}
                helperText={errors.contactFirstName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="input-contact-last-name"
                label={t("contactLastName")}
                value={contactLastName}
                onChange={(event) =>
                  setContactLastName(event.target.value.toUpperCase())
                }
                error={!!errors.contactLastName}
                helperText={errors.contactLastName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="input-contact-email"
                label={t("contactEmail")}
                value={contactEmail}
                onChange={(event) => setContactEmail(event.target.value)}
                error={!!errors.contactEmail}
                helperText={errors.contactEmail}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="input-contact-phone"
                label={t("contactPhone")}
                value={contactPhone}
                onChange={(event) => setContactPhone(event.target.value)}
                error={!!errors.contactPhone}
                helperText={errors.contactPhone}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="select-status">{t("status")}
              </InputLabel>
              <Select
                labelId="select-status"
                id="select-status"
                value={status}
                onChange={(event) => setStatus(event.target.value)}
                name="select-status"
                label="Status"
              >
                <MenuItem value="active"> {t("active")}</MenuItem>
                <MenuItem value="closed"> {t("closed")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.selectedProtocolId}>
              <InputLabel id="select-protocol"> {t("protocol")}
              </InputLabel>
              <Select
                labelId="select-protocol"
                id="select-protocol"
                value={selectedProtocolId}
                onChange={handleProtocolChange}
                name="select-protocol"
                label="Protocol"
              >
                {protocols.map((protocol) => (
                  <MenuItem key={protocol.id} value={protocol.id}>
                    {protocol.reference}
                  </MenuItem>
                ))}
              </Select>
              {errors.selectedProtocolId && (
                <FormHelperText>{errors.selectedProtocolId}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="study-label"> {t("study")}
              </InputLabel>
              <Select
                labelId="study-label"
                id="study-select"
                value={studyCode}
                input={<OutlinedInput label="Study" />}
                disabled
              >
                <MenuItem value={studyCode}>{studyCode}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, padding: 2 }}
            >
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
