const getUniqueOptions = (rows, field, translations = {}, defaultValue = '') => {
  const uniqueValues = new Set();
  rows.forEach((row) => {
    let value = field.split('.').reduce((acc, part) => acc && acc[part] !== null ? acc[part] : null, row);
    if (value === null || value === undefined) {
      value = defaultValue; // Set to defaultValue if undefined or null
    }
    uniqueValues.add(value);
  });
  return Array.from(uniqueValues)
    .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
    .map((value) => ({
      value,
      label: translations[value] || value,
    }));
};

export default getUniqueOptions;